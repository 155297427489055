import React, { useState } from "react";
import { Auth, I18n } from "aws-amplify";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { useAlert } from "react-alert";
import { passwordValidation, verifyError } from "../../utils/verifyErros";
import { Input, InputPassword } from "../../components/Base/Elements";
import Base, { ColumnCenter } from "../../components/Base";
import {
  Button,
  Form,
  FormControl,
  H1,
  InputError,
  Label,
  Hint,
  Link,
  InfoTooltip,
} from "../../assets/styles/GlobalStyles";
import { isInvalidMail } from '../../utils/helpers'

const SignIn = ({ history }) => {
  const alert = useAlert();
  const [passView, setPassView] = useState(null);
  const [passwordValidate, setPasswordValidate] = useState({
    min: false,
    number: false,
    letterlowercase: false,
    letteruppercase: false,
    letterespecial: false,
  });
  const [email, setEmail] = useState("");
  const hasEmail = history.location.state
    ? history.location.state.email || ""
    : "";

  return (
    <Base>
      <ColumnCenter>
        <Formik
          enableReinitialize
          initialValues={{
            email: hasEmail || email,
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(I18n.get("validate.invalid_email"))
              .required(I18n.get("validate.required")),
            password: Yup.string()
              .min(6, I18n.get("validate.min_password"))
              .required(I18n.get("validate.required")),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              if (!passView) {
                try {
                  const response = await Auth.signIn(
                    values.email,
                    values.password
                  );
                  if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
                    setPassView(response);
                    setEmail(values.email);

                    actions.resetForm({
                      ...values,
                      password: "",
                    });
                    alert.show(
                      "Esse é seu primeiro acesso, altere a senha para sua maior segurança",
                      { closeCopy: "Ok" }
                    );
                  } else if (response.challengeName !== "PASSWORD_VERIFIER") {
                    if (
                      response.challengeName == null &&
                      isInvalidMail(response.attributes.email)
                    ) {
                      history.push({
                        pathname: "/primeiro-acesso",
                        search: history.location.search,
                        state: {
                          ...values,
                        },
                      });
                    } else {
                      history.push({
                        pathname: "/",
                        search: history.location.search,
                      });
                    }
                  }
                } catch (e) {
                  if (e.code === "UserNotConfirmedException") {
                    try {
                      await Auth.resendSignUp(values.email);
                      alert.show(I18n.get("send.code.email"), {
                        closeCopy: "Ok",
                      });
                    } catch (e) {
                      alert.show(I18n.get("send.code.email"), {
                        closeCopy: "Ok",
                      });
                    }
                    history.push({
                      pathname: "/cadastro-verificacao",
                      search: history.location.search,
                      state: {
                        email: values.email,
                      },
                    });
                  } else {
                    alert.show(verifyError(e.code, e.message), {
                      closeCopy: "Ok",
                    });
                  }
                }
              } else {
                const loggedUser = await Auth.completeNewPassword(
                  passView, // the Cognito User Object
                  values.password // the new password
                );
                alert.show(
                  "Senha alterada com Sucesso! Faça login Novamente para acessar a plataforma.",
                  {
                    closeCopy: "Ok",
                  }
                );

                await Auth.signIn(values.email, values.password);
                history.push({
                  pathname: "/",
                  search: history.location.search,
                });

                setPassView(loggedUser ? null : loggedUser);
              }
            }
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              {!passView ? (
                <>
                  <FormControl>
                    <Field
                      name="email"
                      placeholder={I18n.get("input.email")}
                      component={Input}
                    />
                    <InfoTooltip>
                      ?
                      <div className="info-message">
                        Caso, seja o seu primeiro acesso, utilize seu e-mail
                        como: seucpf@materdei.com.br
                      </div>
                    </InfoTooltip>
                    <ErrorMessage name="email" component={InputError} />
                  </FormControl>
                  <FormControl>
                    <InputPassword
                      type="password"
                      name="password"
                      placeholder={I18n.get("input.password")}
                      component={Input}
                    />

                    <InfoTooltip>
                      ?
                      <div className="info-message">
                        Utilize seu CPF no primeiro acesso.
                      </div>
                    </InfoTooltip>

                    <ErrorMessage name="password" component={InputError} />
                  </FormControl>

                  <FormControl>
                    <Label htmlFor="terms">
                      <Field type="checkbox" name="term" />
                      Manter conectado
                    </Label>
                  </FormControl>
                </>
              ) : (
                <div>
                  <H1>{I18n.get("login.new_password")}</H1>

                  <Field
                    name="email"
                    placeholder={I18n.get("input.email")}
                    component={Input}
                    type="hidden"
                  />

                  <FormControl>
                    <InputPassword
                      type="password"
                      name="password"
                      placeholder={I18n.get("input.password")}
                      component={Input}
                      onInput={(value) => {
                        passwordValidation(
                          value.target.value,
                          setPasswordValidate
                        );
                      }}
                    />
                    <ErrorMessage name="password" component={InputError} />
                    {/* <Hint>
                      <div>{I18n.get("input.password.hint_title")}</div>
                      <ul>
                        <li
                          className={`${
                            passwordValidate.min ? "complete" : ""
                          }`}
                        >
                          {I18n.get("input.password.hint1")}
                        </li>
                        <li
                          className={`${
                            passwordValidate.number ? "complete" : ""
                          }`}
                        >
                          {I18n.get("input.password.hint2")}
                        </li>
                        <li
                          className={`${
                            passwordValidate.letterlowercase ? "complete" : ""
                          }`}
                        >
                          {I18n.get("input.password.hint3")}
                        </li>
                        <li
                          className={`${
                            passwordValidate.letteruppercase ? "complete" : ""
                          }`}
                        >
                          {I18n.get("input.password.hint4")}
                        </li>
                        <li
                          className={`${
                            passwordValidate.letterespecial ? "complete" : ""
                          }`}
                        >
                          {I18n.get("input.password.hint5")}
                        </li>
                      </ul>
                    </Hint> */}
                  </FormControl>
                </div>
              )}

              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting
                  ? I18n.get(passView ? "button.altering" : "button.entering")
                  : I18n.get(passView ? "button.alter" : "button.enter")}
              </Button>
              {!passView && (
                <FormControl>
                  <Link
                    to={{
                      pathname: "/esqueci-minha-senha",
                      search: history.location.search,
                    }}
                  >
                    {I18n.get("login.resetPassword")}
                  </Link>
                </FormControl>
              )}
            </Form>
          )}
        </Formik>
      </ColumnCenter>
    </Base>
  );
};

export default SignIn;
