// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6f7f2e37-9153-4371-8be7-839af2c9dc36",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_57QMbdPqq",
    "aws_user_pools_web_client_id": "2ijl64840pa9u7f9q6dqpn2cio",
    "oauth": {
        "domain": "",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "",
        "redirectSignOut": "",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

export default awsmobile;