import React from 'react';
import {Normalize} from 'styled-normalize';
import {Main, Wrapper} from './styled';

export * from './ColumnCenter';

const Base = ({children}) => {
  return (
    <>
      <Normalize />
      <Main>
        <Wrapper>{children}</Wrapper>
      </Main>
    </>
  );
};

export default Base;
