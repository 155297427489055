import styled, { createGlobalStyle } from "styled-components";
import { Form as FormFormik } from "formik";
import { Link as LinkRouter } from "react-router-dom";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *, button, input {
    border: 0;
    outline: 0;
    font-family: 'Montserrat', sans-serif;
  }
  
  body{
    font-size: 16px;
    color: #313131;
  }
  
  .complete {
    text-decoration: line-through;
  }
  
  [type="checkbox"]:focus, [type="radio"]:focus {
    box-shadow: inset 0 0 1px 1px #038E83;
  }
`;

export const ButtonDefault = styled.button`
  line-height: 45px;
  cursor: pointer;
  font-size: 12px;
  transition: all 300ms ease;
  font-weight: 600;
  display: block;
  width: 100%;
  margin: 10px 0;
  &[disabled] {
    opacity: 0.4;
    cursor: no-drop;
  }

  @media (min-width: 768px) {
    max-width: 200px;
    align-self: center;
  }
`;

export const Button = styled(ButtonDefault)`
  background: #038e83;
  color: #fff;
  &:hover {
    opacity: 0.8;
    transform: translateY(-3px);
  }
`;

export const ButtonSecondary = styled.button`
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 15px;
`;

export const ButtonNoFormat = styled.div`
  cursor: pointer;
  display: contents !important;
  color: black !important;
  text-decoration: underline;
  font-weight: bold;
  margin: 10px 0;
`;

export const H1 = styled.h1`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin: 25px 0 25px;
`;

export const Form = styled(FormFormik)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`;

export const Label = styled.label`
  color: #313131;
  font-size: 14px;
  text-align: left;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  > input {
    margin-right: 5px;
  }
  > a {
    color: #038e83;
  }
`;

export const Hint = styled.div`
  font-size: 12px;
  color: #676767;
  text-align: left;
  > div {
    margin-top: 3px;
    font-weight: bold;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 11px;
    }
  }
`;

export const InputError = styled.div`
  font-size: 12px;
  color: #990000;
  text-align: left;
  margin-top: 3px;
  text-align: center;
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 20px 0;
  background-color: transparent !important;
  color: #0e0e0e !important;
  font-size: 12px !important;
  padding-left: 15px;
  text-align: center;
  margin-top: 5px;
  outline: none !important;
  border-bottom: 1px solid #b9b9b9 !important;
  font-weight: bold;
`;

export const Link = styled(LinkRouter)`
  display: inline-flex;
  color: #313131;
  font-size: 14px;
  margin: 10px 0;
  margin-top: 25px;
  &:hover {
    text-decoration: none;
  }
`;

export const Relative = styled.div`
  position: relative;
`;

export const CapsLock = styled.div`
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  background: #eee;
  color: #555;
  padding: 0 5px;
  line-height: 22px;
  border: 1px solid #038e83;
  z-index: 9;
  right: 10px;
  display: none;
  &.active {
    display: block;
  }
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    right: 0;
    border-bottom: 7px solid #038e83;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
  &:after {
    content: "";
    position: absolute;
    top: -6px;
    right: 1px;
    border-bottom: 7px solid #eee;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;

export const InfoTooltip = styled.div`
  position: absolute;
  color: black;
  left: 101%;
  bottom: 0;
  color: #038E83;
  border: 3px solid #038E83;
  background-color: white;
  border-radius: 100px;
  width: 33px;
  padding: 5px;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;

  .info-message {
    display: none;
  }

  &:hover .info-message {
    display: block;
    position: absolute;
    top: -3px;
    left: 34px;
    width: 215px;
    z-index: 999;
    background-color: white;
    border: 3px solid #038E83;
    border-radius: 16px;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }

  @media (max-width: 768px) {
    left: 100%;
    width: 29px;
    padding: 3px;

    &:hover .info-message {
      top: -79px;
      left: -200px;
    }
`;
