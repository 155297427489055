import React from 'react';
import { I18n, Auth } from 'aws-amplify'
import Base, { ColumnCenter } from '../../components/Base';
import { ErrorMessage, Field, Formik } from 'formik'
import * as Yup from 'yup'
import { Input, InputPassword } from '../../components/Base/Elements'
import { Button, Form, FormControl, InputError } from '../../assets/styles/GlobalStyles'
import { useAlert } from 'react-alert'
import { api } from '../../services/api'

const FirstAccess = ({ history }) => {
  const alert = useAlert();
  const email = history.location.state ? history.location.state.email : undefined
  const password = history.location.state ? history.location.state.password : undefined

  if (!email || !password) {
    history.replace({
      pathname: '/login',
      search: history.location.search
    })
  }

  const handleSubmit = async (values, actions) => {
    if (values) {
      try {
        const authCognito = await Auth.signIn(email.toLowerCase(), password)
        const authorization = authCognito.signInUserSession.idToken.jwtToken
        const axiosConfig = { headers: { authorization } }
        const response = await api.get('/users/me', axiosConfig)
        if (response.data) {
          const user = response.data
          await api.post(`/users/${user.id}/change_password`, { plainPassword: values.password }, axiosConfig)
          await api.put(`/users/${user.id}`, { email: values.email.toLowerCase() }, axiosConfig)
          alert.show("Email e senha alterados com sucesso", { closeCopy: "Ok" });
          history.replace({
            pathname: '/',
            search: history.location.search
          })
        } else {
          alert.show("Ocorreu um erro ao atualizar os dados. Tente novamente!", { closeCopy: "Ok" });
          history.replace({
            pathname: '/login',
            search: history.location.search
          })
        }
      } catch (e) {
        alert.show("Ocorreu um erro ao atualizar os dados.", { closeCopy: "Ok" });
        history.replace({
          pathname: '/login',
          search: history.location.search
        })
      }
    }
  }

  return (
    <Base>
      <ColumnCenter title="Primeiro acesso, atualize seus dados">
        <Formik
          initialValues={{
            email: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email(I18n.get('validate.invalid_email')).required(I18n.get('validate.required')),
            password: Yup.string()
              .required(I18n.get('validate.required'))
              .min(8, I18n.get('validate.password.min'))
              .max(24, I18n.get('validate.password.max'))
              .matches(/[a-z]/, I18n.get('validate.password.one_lowercase_character'))
              .matches(/[A-Z]/, I18n.get('validate.password.one_uppercase_character'))
              .matches(/[0-9]/, I18n.get('validate.password.one_number'))
              .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('validate.password.one_special_character')),
            confirmPassword: Yup.string().when('password', {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf([Yup.ref('password')], I18n.get('validate.password.not_equals'))
                .required(I18n.get('validate.required')),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormControl>
                <Field name="email" placeholder="Inserir um email valido" component={Input} tabIndex={1} />
                <ErrorMessage name="email" component={InputError} />
              </FormControl>
              <FormControl>
                <InputPassword tabIndex={2} name="password" placeholder="Nova senha" component={Input} />
                <ErrorMessage name="password" component={InputError} />
              </FormControl>
              <FormControl>
                <InputPassword tabIndex={3} name="confirmPassword" placeholder="Confirmar senha" component={Input} />
                <ErrorMessage name="confirmPassword" component={InputError} />
              </FormControl>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? I18n.get('button.altering') : I18n.get('button.alter')}
              </Button>
            </Form>
          )}
        </Formik>
      </ColumnCenter>
    </Base>
  );
};

export default FirstAccess;
