import { useCallback, useEffect } from "react";
import { Auth } from "aws-amplify";
import queryString from "query-string";
import { clearCookie, createCookie } from "../utils/cookies";
import { hasProperty } from "../utils/hasProperty";
import { getRedirect } from "../utils/getRedirect";
import { redirectTo } from "../utils/redirectTo";

const Home = ({ history }) => {
  const goToUrl = useCallback(() => {
    const { location } = history;
    const buildUrlToAdmin = (redirect) => {
      history.push(`${redirect}&adm=true`);
    };

    const buildUrlToWebsite = (redirect) => {
      history.push(redirect);
    };

    if (location.search) {
      const search = queryString.parse(location.search);
      if (hasProperty(search, "adm")) {
        buildUrlToAdmin(getRedirect(search));
      } else {
        buildUrlToWebsite(getRedirect(search));
      }
    } else {
      buildUrlToWebsite(getRedirect());
    }
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const response = await Auth.currentSession();

        clearCookie();
        createCookie(response);

        const { location } = history;
        const search = queryString.parse(location.search);

        if (
          hasProperty(search, "signUp") ||
          location.pathname.indexOf("registre-se") !== -1
        ) {
          goToUrl();
        } else if (hasProperty(search, "logout")) {
          history.push("/sair");
        } else {
          redirectTo(history.location.search);
        }
      } catch (e) {
        clearCookie();
        goToUrl();
      }
    })();
  }, [history, goToUrl]);

  return null;
};
export default Home;
