import React, {useEffect, useState} from 'react';
import {Auth, I18n} from 'aws-amplify';
import * as Yup from 'yup';
import {ErrorMessage, Field, Formik} from 'formik';
import {useAlert} from 'react-alert';
import {clearCookie} from '../../utils/cookies';
import Base, {ColumnCenter} from '../../components/Base';
import {Input, InputPassword} from '../../components/Base/Elements';
import {
  Button,
  Form,
  FormControl,
  H1,
  Hint,
  InputError,
  Link,
} from '../../assets/styles/GlobalStyles';
import {passwordValidation, verifyError} from '../../utils/verifyErros';

const SignUp = ({history}) => {
  const alert = useAlert();
  const [passwordValidate, setPasswordValidate] = useState({
    min: false,
    number: false,
    letterlowercase: false,
    letteruppercase: false,
    letterespecial: false,
  });
  useEffect(() => {
    (async () => {
      clearCookie();
    })();
  }, []);

  return (
    <Base>
      <ColumnCenter title="Cadastro">
        <Formik
          enableReinitialize
          initialValues={{
            name: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(I18n.get("validate.required")),
            email: Yup.string()
              .email(I18n.get("validate.invalid_email"))
              .required(I18n.get("validate.required")),
            password: Yup.string()
              .required(I18n.get("validate.required"))
              .min(6, I18n.get("validate.password.min")),
            // .max(24, I18n.get('validate.password.max'))
            // .matches(/[a-z]/, I18n.get('validate.password.one_lowercase_character'))
            // .matches(/[A-Z]/, I18n.get('validate.password.one_uppercase_character'))
            // .matches(/[0-9]/, I18n.get('validate.password.one_number'))
            // .matches(
            //   /[!@#$%*()_/\\\-+^&{}:;?.]/,
            //   I18n.get('validate.password.one_special_character'),
            // ),
            confirmPassword: Yup.string().when("password", {
              is: (val) => val && val.length >= 8,
              then: Yup.string()
                .oneOf(
                  [Yup.ref("password")],
                  I18n.get("validate.password.not_equals")
                )
                .required(I18n.get("validate.required")),
            }),
          })}
          onSubmit={async (values, actions) => {
            if (values) {
              try {
                const data = {
                  username: values.email.toLowerCase(),
                  password: values.password,
                  attributes: {
                    name: values.name,
                  },
                };

                await Auth.signUp(data);

                alert.show(I18n.get("send.code.email"), {
                  closeCopy: "Ok",
                });

                history.push({
                  pathname: "/cadastro-verificacao",
                  search: history.location.search,
                  state: {
                    email: values.email.toLowerCase(),
                    password: values.password,
                  },
                });
              } catch (e) {
                alert.show(verifyError(e.code, e.message), {
                  closeCopy: "Ok",
                });
                history.push({
                  pathname: "/login",
                  search: history.location.search,
                });
              }
            }
            actions.setSubmitting(false);
          }}
        >
          {({ values, isSubmitting }) => (
            <>
              <Form>
                <H1>{I18n.get("register.title")}</H1>

                <FormControl>
                  <Field
                    name="name"
                    placeholder={I18n.get("input.name")}
                    component={Input}
                    tabIndex={1}
                  />
                  <ErrorMessage name="name" component={InputError} />
                </FormControl>

                <FormControl>
                  <Field
                    name="email"
                    placeholder={I18n.get("input.email")}
                    component={Input}
                    tabIndex={2}
                  />
                  <ErrorMessage name="email" component={InputError} />
                </FormControl>

                <FormControl>
                  <InputPassword
                    type="password"
                    tabIndex={3}
                    name="password"
                    placeholder={I18n.get("input.password")}
                    component={Input}
                    onInput={(value) => {
                      passwordValidation(
                        value.target.value,
                        setPasswordValidate
                      );
                    }}
                  />
                  <ErrorMessage name="password" component={InputError} />
                </FormControl>

                <FormControl>
                  <InputPassword
                    type="password"
                    tabIndex={4}
                    name="confirmPassword"
                    placeholder={I18n.get("input.confirmPassword")}
                    component={Input}
                  />
                  <ErrorMessage name="confirmPassword" component={InputError} />
                  {/* <Hint>
                    <div>{I18n.get('input.password.hint_title')}</div>
                    <ul>
                      <li className={`${passwordValidate.min ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint1')}
                      </li>
                      <li className={`${passwordValidate.number ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint2')}
                      </li>
                      <li className={`${passwordValidate.letterlowercase ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint3')}
                      </li>
                      <li className={`${passwordValidate.letteruppercase ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint4')}
                      </li>
                      <li className={`${passwordValidate.letterespecial ? 'complete' : ''}`}>
                        {I18n.get('input.password.hint5')}
                      </li>
                    </ul>
                  </Hint> */}
                </FormControl>

                <Button type="submit" disabled={isSubmitting}>
                  {isSubmitting
                    ? I18n.get("button.registering")
                    : I18n.get("button.register")}
                </Button>
              </Form>
              <FormControl>
                <Link
                  onClick={() => {
                    history.push({
                      pathname: "/login",
                      search: history.location.search,
                    });
                  }}
                >
                  {I18n.get("register.columnLeft.login")}
                </Link>
              </FormControl>
            </>
          )}
        </Formik>
      </ColumnCenter>
    </Base>
  );
};

export default SignUp;
