import styled from "styled-components";
import icoEye from "../../assets/images/ico-eye.png";

export const Main = styled.div`
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: "#FFF";
`;

export const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #b9b9b9;
  width: 90vw;

  @media (min-width: 768px) {
    width: 45vw;
    min-width: 700px;
    height: fit-content;
    min-height: 50vh;
    min-height: 500px;
    display: flex;
  }
`;

const Column = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    padding: 30px 45px;
  }
`;

export const ColumnRight = styled(Column)`
  align-items: center;
`;

export const Logo = styled.img`
  width: 150px;
  height: 100%;
  margin-right: 25px;
`;

export const IcoView = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url("${icoEye}") no-repeat top center;
  &.slash {
    background-position: bottom center;
  }
`;

export const Title = styled.h1`
  display: flex;
  position: relative;
  font-weight: 200;
  font-size: 2em;
  line-height: 1;
  margin: 0;
  align-self: self-start;
  &:before {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #fcc06d;
  }
`;

export const ContainerLogoTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: start;
    margin-bottom: 25px;
    align-items: initial;
  }
`;
