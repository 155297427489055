import React from 'react';
import PropTypes from 'prop-types';
import {ColumnRight as ColumnRightStyled, Title, Logo,ContainerLogoTitle} from './styled';
import LogoSite from '../../assets/images/logo.png'

const ColumnCenter = ({ children, title = "Fazer login" }) => {
  return (
    <ColumnRightStyled>
      <ContainerLogoTitle>
        <Logo src={LogoSite} />
        <Title>{title}</Title>
      </ContainerLogoTitle>
      {children}
    </ColumnRightStyled>
  );
};

ColumnCenter.propTypes = {
  children: PropTypes.any.isRequired,
};
export {ColumnCenter};
