/* eslint-disable max-len */
export const dictionary = {
  // Inputs and others elements
  'label.email': 'E-mail',
  'label.password': 'Senha',
  'label.code': 'Código',
  'label.newpassword': 'Defina nova senha',
  'input.name': 'Nome e sobrenome',
  'input.email': 'Seu e-mail',
  'input.password': 'Sua senha',
  'input.new_password': 'Nova senha',
  'input.password.hint_title': 'A senha deve ter:',
  'input.password.hint1': 'Mínimo de 6 caracteres',
  'input.password.hint2': '1 número',
  'input.password.hint3': '1 letra minúscula',
  'input.password.hint4': '1 letra maiúscula',
  'input.password.hint5': '1 caractere especial (*&$!)',
  'input.confirmPassword': 'Confirmar senha',
  'input.code': 'Código de verificação',
  'button.enter': 'Entrar',
  'button.alter': 'Alterar',
  'button.altering': 'Alterando',
  'button.entering': 'Entrando..',
  'button.register': 'Cadastre-se',
  'button.registering': 'Cadastrando...',
  'button.confirm': 'Confirmar',
  'button.confirming': 'Confirmando...',
  'button.send': 'Enviar',
  'button.sending': 'Enviando...',
  'button.send.code': 'Enviar código',
  'validate.required': 'Campo obrigatório',
  'validate.invalid_email': 'E-mail inválido',
  'validate.min_password': 'Mínimo de 6 caracteres',
  'validate.password.min': 'Senha com no mínimo 6 caracteres',
  'validate.password.max': 'Senha com no máximo 24 caracteres',
  'validate.password.one_lowercase_character': 'Pelo menos um caractere minúsculo',
  'validate.password.one_uppercase_character': 'Pelo menos um caractere maiúsculo',
  'validate.password.one_number': 'Pelo menos um número',
  'validate.password.one_special_character': 'Pelo menos um caractere especial ex: @,$,!,%...',
  'validate.password.not_equals': 'As senhas não são iguais',
  'validate.code.only_number': 'O código só possui números',
  'login.new_password': 'Você precisa alterar sua senha!',

  // Erros
  'CodeMismatchException.invalid_verification_code':
    'Código de verificação inválido, tente novamente.',
  'ForgotPasswordException.codeSendingSuccess':
    'Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique sua caixa de SPAM ou repita o processo.',
  'ForgotPasswordExceptionImport.codeSendingSuccess':
    'Sua senha expirou! Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de SPAM ou repita o processo.',
  'ForgotPasswordException.defaultError': 'Erro ao tentar recuperar a senha, tente novamente.',
  'ForgotPasswordException.passwordChangedSuccessfully': 'Sua senha foi alterada com sucesso!',
  'NotAuthorizedException.incorrect': 'E-mail e/ou senha incorretos.',
  'UserException.login': 'Ocorreu um erro ao se conectar, tente novamente.',
  'UserNotFoundException.notFound': 'E-mail e/ou senha incorretos.',
  'UsernameExistsException.email_already_exists': 'Já existe uma conta com o e-mail informado.',
  'UserNotConfirmedException.user_is_not_confirmed': 'Usuário não confirmado.',
  'ExpiredCodeException.invalid_code': 'Código inválido/expirado',
  'LimitExceededException.limit_exceeded':
    'Limite de tentativas excedidas, tente novamente mais tarde',
  'NotAuthorizedException.ExpiredTempPassword':
    'A senha temporária expirou e deve ser redefinida por um administrador.',
  'NotAuthorizedException.UserPasswordCantReset':
    'A senha do usuário não pode ser redefinida no estado atual.',

  // Login
  'login.columnLeft.title': 'É o primeiro acesso? Cadastre-se aqui!',
  'login.columnLeft.register': 'Cadastrar',
  'login.title': 'Acesse sua conta',
  'login.resetPassword': 'Esqueci minha senha',

  // Registre-se
  'register.columnLeft.title': 'Já possui cadastro? Clique aqui!',
  'register.columnLeft.login': 'Voltar para o login',
  'register.title': 'Faça seu cadastro',
  'register.columnRight.notify': 'Eu aceito receber alertas de novos conteúdos por e-mail',
  'send.code.email':
    'Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de SPAM ou repita o processo.',

  // Confirmar Registro
  'confirmSignUp.title': 'Informe o código de verificação que enviamos no seu e-mail',
  'confirmSignUp.resendConfirmationCode': 'Reenviar código',
  'confirmSignUp.codeResentSuccessfully': 'Código enviado com sucesso',
  'confirmSignUp.errorResendingCode': 'Erro ao enviar código, tente novamente.',
  'confirmSignUp.verifiedUser': 'Usuário verificado com sucesso.',

  'forgotPassword.title':
    'Informe o e-mail cadastrado e receba um e-mail para cadastrar uma nova senha',
  'passwordRecovery.title': 'Informe o código que enviamos no seu e-mail e cadastre uma nova senha',
  'newPassword.title': 'Informe o código que enviamos no seu e-mail e cadastre uma nova senha',
};
